.mdl-cart{
  padding: 8rem 0;

  @media(max-width: $md){
    padding: 4rem 0;
  }

  .container{
    &__bx{
      flex-flow: column;
    }
  }

  &__it {
    width: 100%;
    margin-bottom: 3rem;
    padding-bottom: 5rem;
    border-bottom: 1px solid #50515266;

    .title{
      margin-bottom: 2rem;
    }
  }

  &__cl{
    display: flex;
    align-items: center;
    
    @media(max-width: $md){
      flex-flow: column;
      align-items: flex-start;
      margin-bottom: 2rem;
    }
  }

  &__prod {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;

    @media(max-width: $md){
      flex-flow: column;
      margin-bottom: 2rem;
    }
  }

  &__img {
    width: 20rem;
    padding-bottom: 22.5%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 3.8rem;
  }

  &__txt{
    font-size: 2rem;
    line-height: 3rem;
    font-family: $font_merri;

    .tit{
      font-size: 3rem;
    }

    ul,
  ol{
    margin-bottom: 1rem;
    li{
      position: relative;
      padding-bottom: 1rem;

      &:last-child{ 
        padding-bottom: 0;
      }

    }
  }

  ul{

    li{
      padding-left: 1.5rem;
      font-size: 1.8rem;

      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 1.1rem;
        width: 0.5rem;
        height: 0.5rem;
        background-color: $green;
        border-radius: 100%;
      }
    }
  }

  ol{
    padding-left: 2rem;
    li{
      list-style: auto;
    }
  }
  }

  &__info {
    display: flex;

    @media(max-width: $md){
      width: 100%;
      justify-content: space-between;
    }
  }

  .btns {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 4rem;
    align-items: center;
    margin-top: 2rem;
  }

  &__foot {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem;

    @media(max-width: $md){
      flex-flow: column;
    }

    .total{

    }
  }

  .result {
    font-family: $font_merri;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3rem;
    letter-spacing: 0em;
    text-align: right;
    p{
      margin-bottom: 3rem;
    }
    .price{
      width: 14rem;
      display: inline-flex;
    }
  }

  &__prod{
    padding-left: 3rem;
  }

  &__img {
    width: 12.5rem;
    padding-bottom: 0;
    height: 12.5rem;
  }
  
  &__txt{
    
    p{
      font-size: 1.8rem;

      &.tit{
        margin-bottom: 1rem;
        font-size: 2rem;
      }
    }
  }
  

}
 
.empty-cart{
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  font-family: $font_merri;
  
  img{
    max-width: 16rem;
    margin-bottom: 4rem;
    margin-right: 4rem;
    opacity: 0;
    transform: translateX(-100%);
    animation: aparecerAnimacion 1s ease-in-out forwards;
  }

  h3{
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 2.8rem;
    margin-bottom: 2rem;
  }

  p{
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 3rem;
  }
}

@keyframes aparecerAnimacion {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.total-bx{ 
  font-family: $font_merri;
  font-size: 2rem;
  line-height: 3rem;
  text-align: right;

  @media(max-width: $md){
    margin-top: 4rem;
  }

  &__rw{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5rem;
    padding-left: 4rem;

    @media(max-width: $md){
      padding: 0;
      font-size: 1.6rem;
      line-height: 2.6rem;
    }

    &:last-child{
      border-top: 1px solid #50515266;
      margin-top: 2rem;
      padding-top: 2rem;
    }

  }

  &__cl{
    
    &.rg {
      width: 20rem;
    }

    &.lf{
      width: 25rem;
    }
  }

}

.resume {
  background-color: rgba(0,0,0,0.1);
  border-top-left-radius: 2.4rem;
  border-bottom-left-radius: 2.4rem;
  padding: 4.6rem 4.6rem 16rem;
  font-family: $font_merri;
  font-size: 1.6rem;
  line-height: 2.2rem;

  &::after{
    content: '';
    left: 100%;
    width: 1000px;
    background-color: rgba(0,0,0,0.1);
    height: 100%;
    display: block;
    right: 0;
    top: 0;
    position: absolute;
  }

  &__gp{
    margin-bottom: 1.5rem;

    .resume{
      &__rw{
        &--sm{
          padding-bottom: 0.5rem;
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
      }
    }
  }

  &__rw{
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    &.ls{
      margin-top: 0.5rem;
      padding-top: 2rem;
      border-top: 1px solid $green;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &__cl{
    &.lf{
      padding-right: 1rem;
    }
  }

  .tit{
    font-size: 3rem;
    line-height: 3rem;
    padding-bottom: 5rem;
  }
}

.price{
  font-size: 2rem;
  line-height: 2rem;
  font-family: $font_merri;
  margin-left: 2rem;
  min-width: 12rem;
  text-align: right;

  @media(max-width: $md){
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.js-foodmenu_wrapper{

  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;

    @media(max-width: $md){
      flex-flow: column;
      align-items: flex-start;
    }

    .delete{
      margin-left: 0;
      padding-right: 6rem;
      background-position: right;
      background-repeat: no-repeat;
      width: auto;
      font-family: $font_merri;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      letter-spacing: 0em;
      text-align: left;
      cursor: pointer;
      color: $black;
      text-decoration: none;

      @media(max-width: $md){
        margin-top: 2rem;
        align-self: flex-end;

        span{
          display: none;
        }
      }
    }
  }

  .js-productcategory_name{
    padding-left: 3rem;
    font-family: $font_bench;
    font-size: 3rem;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 3rem;
    display: flex;
  }

  
}

.mdl-order{
  padding: 8rem 0;
  overflow: hidden;

  h3{
    font-family: $font_merri;
    font-size: 2rem;
    font-weight: 700; 
    margin-bottom: 1rem;
  }

  .tit{
    font-size: 2.4rem;
    line-height: 3.4rem;
    margin-bottom: 2rem;
  }

  &__cl{
    &.lf{
      width: 70%;
      @media(max-width: $md){
        width: 100%;
      }
    }

    &.rg{
      width: 30%;
      position: relative;
      @media(max-width: $md){
        width: 100%;
      }
    }
  }

  .container{
    &__bx{
      // flex-flow: column;
      align-items: flex-start;
      // position: relative;
    }
  }

  &__bx{
    margin-bottom: 4rem;
    width: 100%;

  }

  .form{
    max-width: 54.2rem;

    &--xl{
      max-width: 100%;

      .f-grid{
        max-width: 72rem;
      }
    }

    
  }

  .btn{
    margin-top: 2rem;
  }

  .f-grid{
    margin-bottom: 2.4rem;
  }

  em{
    font-family: $font_merri;
    font-size: 1.6rem;
    font-style: italic;
    line-height: 2.4rem;
  }

  textarea{
    margin-bottom: 4rem;
    line-height: 2rem;
    min-height: 12rem;
    border: 0.1rem solid #000;
    padding: 1rem;

    &::placeholder{
      font-style: italic;
      opacity: 0.6;
    }
  }

  p{
    font-size: 1.6rem;
    font-family: $font_merri;
    margin-bottom: 2.2rem;
  }

  &__btn{
    margin-bottom: 2rem;
  }
}

.mdl-feedback{
  padding: 8rem 0;

  .container{
    &__bx{
      flex-flow: column;
    }
  }

  .btn{
    margin-top: 4.4rem;
  }

  p{
    font-family: $font_merri;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 1.5rem;

    strong{
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 2.8rem;
    }

  }

  svg {
    width: 20rem;
    display: block;
    margin: 0 auto 6rem;
  }
  
  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle {
      -webkit-animation: dash .9s ease-in-out;
      animation: dash .9s ease-in-out;
    }
    &.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash .9s .35s ease-in-out forwards;
      animation: dash .9s .35s ease-in-out forwards;
    }
    &.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check .9s .35s ease-in-out forwards;
      animation: dash-check .9s .35s ease-in-out forwards;
    }
  }
}


@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}