.breadcrumbs{
  background-color: #EDE9EB;
  padding: 2rem 0;
  font-size: 1.8rem;
  line-height: 2.2rem;

  .container{
    &__bx{
      justify-content: space-between;

      @media(max-width: $md){
        flex-flow: row;
      }
    }
  }

  ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    text-align: right;
    
    li{
      &.current{
        font-weight: 700;
      }

      &::before{
        content: '/';
        padding: 0 1rem;

        @media(max-width: $md){
          padding: 0 0.5rem;
        }
      }

      &:first-child{
        &::before{
          display: none;
        }
      }
    }
  }

  a{
    text-decoration: none;
    color: $black;
  }

  .btn-return{
    margin-bottom: 0;

    @media(max-width: $md){
      padding-left: 4rem;
    }

    span{
      @media(max-width: $md){
        display: none;
      }
    }

    
  }
}