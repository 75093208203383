.errors{
    padding: 15rem 0;
    text-align: center;

    .container{
        &__bx{
            flex-flow: column;
        }
    }
    span{
        font-size: 10rem;
        line-height: 10rem;
        color: $green;
        font-weight: 700;
        margin-bottom: 2rem;
    }

    h1{
        margin-bottom: 2rem;
        font-size: 3rem;
        line-height: 3.6rem;
    }

    p{
        margin-bottom: 4rem;
    }
    
}