.btn{
  max-width: 17.7rem;
  width: 100%;
  border-radius: 3rem;
  background-color: $green;
  height: 5rem;
  border: 0.1rem solid $green;
  color: $white;
  font-family: $font_bench;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 700;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover{
    background-color: $white;
    color: $green;
  }

  &--md{
    max-width: 32rem;
  }

  &--lg{
    max-width: 24.1rem;
  }

  &--xl{
    max-width: 43.9rem;
  }

  &.center {
    align-self: center;
  }
}

.btn-return{
  background-image: url('../imgs/bt-return.svg');
  background-repeat: no-repeat;
  color: $black;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0.4px;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 6rem;

  @media(max-width: $md){
    margin-bottom: 2rem;
    align-self: start;
  }
}

.link{
  font-family: $font_bench;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $black;
  text-decoration: none;
}

.btn-search {
  // top: 50%;
  // right: 0;
  // transform: translateY(-50%);
  // position: fixed;
  border: 0;
  background-color: $green;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 99;
  border-radius: 3rem;
  width: 100%;
  max-width: 36.2rem;
  height: 5.7rem;
  padding: 1rem 2.6rem;

  
  @media(max-width: $lg){
    max-width: 5.7rem;
    padding: 1.2rem;
    margin: 0 1rem;
  }
  
  img{
    max-width: 3.3rem;
  }

  span{
    font-family: $font_bench;
    color: $white;
    margin-left: 2rem;
    font-size: 1.8rem;
    line-height: 2.4rem;
    // display: none;

    @media(max-width: $lg){
      display: none;
    }
  }

  // &:hover{
  //   span{
  //     display: block;
  //   }
  // }

}