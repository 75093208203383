.mdl-icons{
    padding: 4rem 0 10rem;

    .container{
        &__bx{
            flex-flow: column;
        }
    }

    h2{
        font-family: $font_bench;
        font-size: 54px;
        font-weight: 700;
        line-height: 64px;
        letter-spacing: 0.02em;
        text-align: center;
        color: $green;
        margin-bottom: 4rem;
    }
    

    &__bx{
        display: flex;
        gap: 4rem;
        justify-content: space-between;

        @media(max-width: $xl){
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__it{
        display: flex;
        align-items: center;
        flex-flow: column;
        width: 14.28%;
        position: relative;

        @media(max-width: $xl){
            width: calc(25% - 4rem);
        }

        @media(max-width: $sm){
            width: calc(50% - 4rem);
        }

        a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img{
            width: 100%;
            max-width: 12rem;
            margin-bottom: 2rem;
        }

        p{
            font-family: $font_merri;
            font-size: 2rem;
            font-weight: 700;
            line-height: 2.5rem;
            letter-spacing: 0.02em;
            text-align: center;

            @media(max-width: $sm){
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }
    }

    &__fancy{
        padding: 5rem!important;
        font-family: $font_merri;

        h2{
            font-family: $font_bench;
            color: $green;
            font-size: 3rem;
            margin-bottom: 3rem;
            font-weight: 700;
        }
        
        p{
            margin-bottom: 2rem;

            a{
                color: $green;
            }
        }



    }
}