.mdl-blocks{
  padding: 8rem 0;

  .container{
    &__bx{
      display: grid;
      grid-template-columns: repeat(3,1fr);
      gap: 3rem;

      @media(max-width: $md){
        grid-template-columns: repeat(1,1fr);
        gap: 1rem;
      }
    }
  }

  &.fancy{
    padding: 3rem 0 5rem;
    .container{
      max-width: 90rem;
    }
    .mdl-blocks {
      
      &__it {
        a{
          background-image: none;
        }
      }
    }
  }

  &__two-col{
    .container{
      &__bx{
        grid-template-columns: repeat(2,1fr);
        align-items: flex-start;
  
        @media(max-width: $md){
          grid-template-columns: repeat(1,1fr);
        }
      }
    }
    // .mdl-blocks{
    //   &__it{

    //     // a{
    //     //   background-image: url('../imgs/icon-more.svg');
    //     //   background-repeat: no-repeat;
    //     //   background-position: bottom 4rem center;
    //     // }
    //   }

    //   &__cn{
    //     flex-flow: column;
    //   }
    // }
  }

  &__txt{
    font-size: 1.8rem;
    line-height: 3rem;
    font-family: $font_merri;

    p{
      margin-bottom: 1.2rem;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  &__it{
    padding-bottom: 73%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    // background-color: $black;

    &:hover{
      .mdl-blocks{
        &__img{
          transform: scale(1.1);
        }

      }
    }

    a{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 5;
    }
  }

  &__cn{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 3rem;
    font-weight: 700;
    line-height: 4rem;
    padding: 1rem 2rem;
    text-align: center;

    &::after{
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 10%, rgba(255,255,255,0) 30%, rgba(255,255,255,0) 100%);
      z-index: -1;
      position: absolute;
    }

    p{
      align-self: flex-end;
      width: 100%;
    }
  }

  &__img{
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    transition: all 300ms linear;
    // opacity: 0.5;
  }

}