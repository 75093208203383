.footer{
  font-family: $font_merri;
  font-size: 1.4rem;
  line-height: 2.2rem;
  background-image: url('../imgs/bg-footer.png');
  background-color: #EDE9EB;
  padding: 4.7rem 0 4rem;
  .container{
    &__bx{
      display: grid;
      grid-template-columns: repeat(3,1fr);

      @media(max-width: $md){
        grid-template-columns: repeat(1,1fr);
      }
    }
  }

  img{
    margin-bottom: 2rem;
    max-width: 20rem;
  }

  &__cl{
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    &.lf{

      @media(max-width: $md){
        padding-right: 0;
      }

      p{
        text-align: center;
        margin-bottom: 1rem;
      }
    }

    &.cn{
      padding: 0 5rem;
      text-align: center;

      @media(max-width: $md){
        padding: 3rem 0;
      }
    }

    &.rg{

      @media(max-width: $md){
        padding-left: 0;
      }
    }
  }
}

.footer-links{
  text-align: center;
  
  a{
    color: $purple;
    text-decoration: none;
  }

  span{
    padding: 0 2.8rem;

    @media(max-width: $lg){
      padding: 0 1rem;
    }
  }

}

.footer-copy{
  background-color: $purple;
  color: $white;
  font-family: $font_merri;
  padding: 2.4rem 0;

  &__cl{
    width: 50%;

    @media(max-width: $md){
      width: 100%;
    }

    &.rg{
      display: flex;
      justify-content: flex-end;

      @media(max-width: $md){
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}

.footer-menu{
  font-size: 1.1rem;
  line-height: 2.2rem;

  @media(max-width: $md){
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  a{
    color: $white;
    text-decoration: none;
    font-size: 1.1rem;
    line-height: 2.2rem;

    &::before{
      content: '|';
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      @media(max-width: $md){
        display: none;
      }
    }
  }
}

.rrss{
  display: inline-flex;
  a{
    margin: 0 1.2rem;
  }
}

.backtotop{
  width: 3.9rem;
  height: 3.9rem;
  background-color: transparent;
  border: 0;
  background-image: url('../imgs/bt-backtotop.svg');
  margin-left: 2.6rem;
  cursor: pointer;
}