.login {
    padding-top: 6rem;
    display: flex;
    flex-flow: column;
    align-items: center;

    &--pass{
        padding-bottom: 6rem;
    }

    &__btn{
        padding: 5rem 2rem;
        background: #0000000D;
        width: 100%;
        display: flex;
        justify-content: center;

        &--tr{
            background-color: transparent;
        }
    }

    h1{
        font-family: $font_bench;
        font-size: 5rem;
        font-weight: 700;
        line-height: 6rem;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 3rem;
    }

    &__bx{
        width: 100%;
        max-width: 68.2rem;
        background: #0000000D;
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 5rem 4rem;
        margin-bottom: 5rem;
    }
    
    form {
        width: 100%;
        max-width: 32rem;

        .btn{
            max-width: 100%;
            margin-bottom: 20px;
        }

        a{
            color: inherit;
        }
    }
}

.register{
    padding: 8rem 0 6rem;

    .container{
        &__bx{
            justify-content: flex-start;
            align-items: flex-start;  
            flex-flow: column;  
        }
    }

    h2{
        color: #000;
        font-family: $font_merri;
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    p{
        font-size: 2rem;
        font-family: $font_merri;
        margin-bottom: 4rem;

    }

    form{
        p{
            font-size: 2.4rem;
            line-height: 2.4rem;
            margin-bottom: 3rem;
        }

        .btn{
            margin: 4rem auto;
        }

        .f-grid{
            margin-bottom: 2rem;

            .f-grid{
                margin-bottom: 0;
            }
        }
    }
}

.register-fancy {
    padding: 8rem 2rem 8rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    h3{
        margin-bottom: 5rem;
        font-size: 5rem;
        font-weight: 700;
        line-height: 6rem;
        text-align: center;
        color: $black;
    }

    p{
        font-family: $font_merri;
        margin-bottom: 6rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: $black;

        strong{
            font-size: 2.4rem;
            font-weight: 700;
            line-height: 4rem;
        }

    }
}

.alert {
    margin-bottom: 2rem;
    font-size: 2.2rem;
    font-style: italic;
        color: #A12713;
    
    /*&.alert-warning {
        margin-bottom: 2rem;
        font-size: 2.2rem;
        
    }*/
}

.form-control {
    font-family: $font_merri;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    border: 0;
    margin-bottom: 2rem;
    background-color: transparent;
    border-bottom: 0.1rem solid $black;
    border-radius: 0;
    padding: 0;
    padding-bottom: 2rem;
    position: relative;

    &::placeholder{
        opacity: 1;
        color: $black;
    }

    &--password{
        padding-right: 4rem;
    }
}

.form-group{
    position: relative;
}

.form-label{
    font-family: $font_merri;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    display: block;
}

.f-grid {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 2rem 4rem;

    @media(max-width: $md){
        grid-template-columns: repeat(1,1fr);
    }
}

.radio-buttons{
    display: inline-flex;
    margin-bottom: 3rem;

    &--block{
        flex-flow: column;

        .radio-buttons{
            &__it{
                margin-bottom: 2rem;
            }
        }
    }

    &__it{
        margin-right: 3rem;

        input[type="radio"] {
            height: 0.1rem;
            position: absolute;
        }
        
        label {
            cursor: pointer;
            position: relative;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-family: $font_merri;
            padding-left: 2.5rem;
            padding-left: 3.5rem;
            display: inline-block;

            &::before {
                content: "";
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                background-color: #D9D9D9;
                border: 0;
                border-radius: 50%;
                top: 1.3rem;
                left: 0;
                transform: translateY(-50%);
                transition: border-color 400ms ease;
            }

            &::after {
                content: "";
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                background-color: $green;
                border-radius: 50%;
                border: 0;
                top: 1.3rem;
                left: 0;
                transform: translateY(-50%) scale(0);
                transition: transform 400ms ease;
            }
        }
        
        
        input[type="radio"]:checked + label::before {
            border-color: $green;
        }
        
        input[type="radio"]:checked + label::after {
            transform: translateY(-50%) scale(0.55);
        }
    }
}

.check-buttons{
    display: inline-flex;
    margin-bottom: 4rem;

        input[type="checkbox"] {
            height: 0.1rem;
            position: absolute;
        }
        
        label {
            cursor: pointer;
            position: relative;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-family: $font_merri;
            padding-left: 2.5rem;
            padding-left: 3.5rem;
            display: inline-block;

            a{
                text-decoration: none;
                color: inherit;
            }

            &::before {
                content: "";
                position: absolute;
                width: 2.4rem;
                height: 2.4rem;
                background-color: #D9D9D9;
                border: 0;
                border-radius: 0;
                top: 1.4rem;
                left: 0;
                transform: translateY(-50%);
                transition: border-color 400ms ease;
            }

            &::after {
                content: "";
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                background-color: $green;
                border-radius: 0;
                border: 0;
                top: 1.4rem;
                left: 0;
                transform: translateY(-50%) scale(0);
                transition: transform 400ms ease;
            }
        }
        
        
        input[type="checkbox"]:checked + label::before {
            border-color: $green;
        }
        
        input[type="checkbox"]:checked + label::after {
            transform: translateY(-50%) scale(0.55);
        }
}