@import url('https://fonts.googleapis.com/css2?family=BenchNine:wght@300;400;700&family=Merriweather&display=swap');

$font_bench: 'BenchNine', sans-serif;
$font_merri: 'Merriweather', serif;

//colors
$black: #000;
$white: #fff;
$purple: #441229;
$green: #77A013;

//media queries
$sm: 575.98px;
$md: 767.98px;
$lg: 991.98px;
$xl: 1199.98px;
$xxl: 1399.98px;