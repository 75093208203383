.mdl-profile{
  padding: 8rem 0;

  .container{
      &__bx {
          align-items: start;
          justify-content: flex-start;
      }
  }

  &__cl{
      &--lf{
          width: 26%;

          @media(max-width: $md){
              width: 100%;
              margin-bottom: 5rem;
          }
      }

      &--rg{
          width: 74%;
          padding-left: 12rem;
          border-left: 1px solid #50515266;

          @media(max-width: $md){
            width: 100%;
            border-left: 0;
            padding-left: 0;
          }
      }
  }

  h2{
      font-size: 4.2rem;
      font-weight: 700;
      line-height: 5rem;
      letter-spacing: 1.08px;
  }

  h3{
      font-family: $font_merri;
      font-size: 2rem;
      font-weight: 700;
  }

  p{
      font-family: $font_merri;
      font-size: 2.4rem;
      line-height: 2.4rem;
      margin-bottom: 3.2rem;

      &.tit{
          font-size: 1.6rem;
      }
  }

  .f-grid{
      margin-bottom: 4rem;
      .f-grid{
          margin: 0;
      }
  }

  form{
    display: flex;
    flex-flow: column;

    .btn{
      align-self: flex-end;
    }
  }
}

.mdl-fb{
  padding: 8rem 0;

  .container{
      &__bx {
          align-items: center;
          justify-content: center;
      }
  }

  .text{
    font-size: 2.2rem;
  }

  .f-grid{
    margin-bottom: 3rem;
    > div{
      position: relative;
    }
  }

  #pass_form {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.field-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 4rem;
  background-image: url('../imgs/eye-solid.svg');
  width: 3rem;
  height: 1.8rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;

  &--on{
    background-image: url('../imgs/eye-low-vision-solid.svg');
  }
}

.rwd-table {
    margin: auto;
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    color: #000;
    font-family: $font_merri;
    font-size: 1.6rem;
    line-height: 2.4rem;

    .green{
        color: $green;
    }

    .link{
        color: $green;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.6rem;
        border: 0;
        padding: 0;
        background-color: transparent;
    }

    tr{
        border-bottom: 0.1rem solid $green;

        @media(min-width: $md){
            border: 0;
        }
    } 
}

  .rwd-table th {
    display: none;
  }
  
  .rwd-table td {
    display: block;
  }
  
  .rwd-table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 120px;
    display: inline-block;
    color: #000;
  }
  
  .rwd-table th,
  .rwd-table td {
    text-align: left;
  }
  
  
  .rwd-table th,
  .rwd-table td {
    padding: .5em 0;
  }
  @media screen and (max-width: 601px) {
    .rwd-table tr:nth-child(2) {
      border-top: none;
    }
  }
  @media screen and (min-width: $md) {
    .rwd-table td:before {
      display: none;
    }
    .rwd-table th,
    .rwd-table td {
      display: table-cell;
      padding: .5rem 0;
    }
    .rwd-table th:first-child,
    .rwd-table td:first-child {
      padding-left: 0;
    }
    .rwd-table th:last-child,
    .rwd-table td:last-child {
      padding-right: 0;
    }
  }

.m-profile{
    font-size: 2rem;
    line-height: 3.2rem;
    font-family: $font_merri;
    text-transform: uppercase;
    font-weight: 700;
    color: $black;
    display: flex;
    flex-flow: column;

    a{
        color: $black;
        text-decoration: none;
        margin-bottom: 1.6rem;
        padding-left: 4.8rem;
        background-size: 3.2rem;
        background-repeat: no-repeat;
        background-position: left center; 

        &.active{
            color: $green;
        }
    }
}

.mdl-resume{
  padding-top: 3rem;
  padding-bottom: 5rem;

  .container{
    &__bx{
      flex-flow: column;
    }
  }

  &__it{
    width: 100%;
    max-width: 80rem; 
    margin-bottom: 2rem;

    .title {
      font-size: 3rem;
      line-height: 4rem;
    }
  }

  .rwd-table{

    &--total{
      border-top: 1px solid rgba(0,0,0,0.1);

      @media(max-width: $md){
        border: 0;
      }

      td{
        &::before{
          display: none;
        }
      }

      tr{
        @media(max-width: $md){
          display: flex;
          justify-content: space-between;
        }
      }
    }

    th{
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    td[colspan] {
      padding: 0;

      @media(max-width: $md){
        display: none;
      }
    }
    
    .border{
      border-top: 1px solid rgba(0,0,0,0.1);
      @media(max-width: $md){
        border: 0;
      }
    }

    .size{
      width: 15rem;
      @media(max-width: $md){
        width: auto;
      }
    }
    
    .left{
      text-align: left;
      @media(max-width: $md){
        text-align: left;
      }
    }

    .right{
      text-align: right;
      @media(max-width: $md){
        text-align: left;
      }
    }

    .center{
      text-align: center;
      @media(max-width: $md){
        text-align: left;
      }
    }


  }

}