.mdl-product{
  padding: 8rem 0 6rem;

  &--fancy{
    padding-bottom: 0;
    padding: 5rem 0 3rem;

    .container{
      max-width: 100rem;
    }
  }

  .container{
    &__bx{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap: 3.8rem;
      align-items: flex-start;

      @media(max-width: $md){
        grid-template-columns: repeat(1,1fr);
      }
    }
  }

  &__cl{
    &.lf{
      img{
        width: 100%;
      }
    }

    &.rg{
      .title{
        margin-bottom: 4rem;
      }
      h3{
        font-size: 2.4rem;
        line-height: 3rem;
        margin-bottom: 3rem;
        font-family: $font_merri;

        @media(max-width: $md){
          font-size: 2rem;
          line-height: 2.6rem;
        }
      }
      .txt{
        margin-bottom: 3rem;
        p{
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.prod-info{
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin-bottom: 5.6rem;
  
  img{
    margin-right: 1rem;
    max-width: 5rem;
  }
}

.mdl-prod-foot{
  padding-bottom: 8rem;

  @media(max-width: $md){
    padding-bottom: 4rem;
  }

  &__btns{
    display: inline-flex;
    margin-top: 3rem;

    @media(max-width: $md){
      flex-flow: column;
      align-items: center;
    }

    a{
      margin-right: 2rem;
      @media(max-width: $md){
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }
  
  form{
    width: 100%;
    font-size: 2rem;
    line-height: 2rem;
    font-family: $font_merri;
    display: flex;
    flex-flow: column;

    .title{
      margin-bottom: 4rem;
      font-family: $font_bench;
    }

    ul{
      li{
        display: flex;
        margin-bottom: 2rem;
        justify-content: space-between;

        @media(max-width: $md){
          flex-flow: column;
        }
        .cl{
          display: flex;

          &:nth-child(1){
            @media(max-width: $md){
              margin-bottom: 2rem;
            }
           
          }

          &:nth-child(2){
            @media(max-width: $md){
              justify-content: space-between;
            }
           
          }

          .price{
            margin-left: 1rem;
            min-width: 12rem;
            text-align: right;
          }
        }
      }
    }
  }
}

.total {
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid #50515266;
  padding-bottom: 6rem;
  display: flex;
  justify-content: flex-end;
  padding-right: 6rem;

  span{
    margin-left: 1rem;
    min-width: 14rem;
    text-align: right;
  }
}

.info{
  width: 2rem;
  font-size: 1.6rem;
  height: 2rem;
  border-radius: 50%;
  background-color: $green;
  background-image: url('/imgs/ico-info.svg');
  display: block;
  margin-right: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.4rem;
  min-width: 2rem;
  min-height: 2rem;
}

.info-empty{
  width: 2rem;
  height: 2rem;
  display: block;
  margin-right: 2rem;
}

.delete{
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url('/imgs/trash.svg');
  display: block;
  border: 0;
  background-color: transparent;
  padding: 0;
  border: 0;
  margin-left: 3rem;
  margin-left: 2rem;
  background-size: 2.2rem;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 2.4rem;
  min-height: 2.4rem;
}

.prod-list{
  &__img{
    position: relative;
    height: 32.8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: end;
    margin-bottom: 5rem;
    position: relative;

    .title{
      font-family: $font_bench;
      font-size: 5rem;
      line-height: 6rem;
      letter-spacing: 0.02em;
      text-align: center;
      color: $white;
      padding-bottom: 5rem;
      z-index: 99;
      position: relative;
    }
  }

  &__bg{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.46%, rgba(0, 0, 0, 0.5) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
  }

  .container{
    padding: 0 4rem;
  }

  &__itms {
    width: 100%;
    display: flex;
    flex-flow: column;  

    a{
      font-family: $font_merri;
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 2.4rem;
      text-align: left;
      color: $black;
      text-decoration: none;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $black;
      margin-bottom: 3rem;
      background-image: url('/imgs/right.svg');
      background-position: top 7px right;
      background-repeat: no-repeat;
    }
  }

  &--fancy{

    .container{
      @media(max-width: $md){
        padding: 0;
      }
    }

    .mdl-prod-foot{
      // padding-bottom: 0;
      width: 100%;
      form{
        ul{
          li{
            border-bottom: 0.1rem solid $black;
            padding: 2rem 0;
            margin-bottom: 0;
            align-items: center;
          }
        }
      }

      &__cl {
        display: flex;
        align-items: center;

        &--lf{
          width: 65%;
          padding-right: 2rem;

          @media(max-width: $md){
            width: 100%;
            padding-right: 0;
          }
        }
        &--rg{
          width: 35%;
          justify-content: flex-end;

          @media(max-width: $md){
            width: 100%;
            margin-top: 2rem;
          }

          .price{
            min-width: auto;
            margin-left: 0;
          }
        }

        .btn{
          margin: 0 3.5rem;
          width: fit-content;
          padding: 0 3.5rem;
        }
      }
    }
  }
}

.mdl-title{
  padding-top: 5rem;
}

.mdl-prod-mod{
  padding-top: 3.6rem;
  padding-bottom: 7.5rem;

  form{
    display: inline-flex;
    align-items: center;

    .js-product_product_wrapper {
      display: inline-flex;
      align-items: center;
      margin-right: 3rem;
    }
  
    .btn{
      width: 24.1rem;
      max-width: 24.1rem;
    }

    .price{
      font-family: $font_merri;
      letter-spacing: 0em;
      margin-left: 3rem;
      display: inline-flex;
    }
  }
}

.form-select{
  width: 14rem;
  max-width: 14rem;
  border: 0; 
  border-bottom: 1px solid;
  text-align: center;
  font-family: $font_merri;
  font-size: 2.2rem;
  line-height: 4.4rem;
  background-color: $white;
  border-radius: 0;
  border-color: $black;
  color: $black;
}