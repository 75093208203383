.header{
  padding: 2.8rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255,255,255,0.8);
  z-index: 10;

  .container{

    @media(max-width: $md){
      flex-flow: row;
    }

    &__bx{
      justify-content: space-between;
      position: relative;

      @media(max-width: $md){
        flex-flow: row;
      }
    }
  }

  .cl{
    &.rg{
      display: flex;
      align-items: center;
    }
  }

  .logo{
    img{
      max-width: 20rem;
      width: 100%;
    }
  }

  &.js-sticky{
    box-shadow: 1px 1px 8px #ccc;
    &.js-open{
      box-shadow: none;
    }
  }

  .ico-user,
  .ico-logout{
    @media(max-width: $md){
      display: none;
    }
  }
}

.menu{
  ul{
    display: flex;
    flex-flow: row;
    li{
      padding: 0 1rem;
      position: relative;
      

      &.has-child{
       
        > a{

          &:after{
            content: '';
            background-image: url('../imgs/chevron-down-solid.svg');
            background-repeat: no-repeat;
            background-size: 1.4rem;
            background-position: right center;
            display: inline-block;
            width: 20px;
            height: 11px;
          }
          
        }

        &:hover{
          > .submenu{
            display: flex;
          }

          > a
            &:after{
              transform: rotate(180deg);
              transition: 0.4s ease;
            }
        }
      }

      

      &.selected{
        a{
          font-weight: 700;
        }
      }

      a{
        text-decoration: none;
        display: block;
      }

      ul.submenu {
        flex-flow: column;
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        background-color: $white;
        padding: 0 1rem;
        width: 13rem;

        li{
          padding: 0 1rem;
          width: 100%;
          display: block;

          &.has-child{
            > a{
              &:after{
                content: '';
                background-image: url('../imgs/chevron-right-solid.svg');
                background-repeat: no-repeat;
                background-size: 0.8rem;
                background-position: right center;
                display: inline-block;
                width: 20px;
                height: 11px;
              }
            }
          }

          ul.submenu {
            position: absolute;
            width: 100%;
            left: 100%;
            top: 0;
          }
        }
      }
    }
  }

  @media(max-width: $lg){
    display: none;
  }
}

.lang-sel{
  text-transform: uppercase;
  position: relative;

  &__current{
    padding-right: 1.5rem;
    cursor: pointer;

    &::after{
      content: '';
      background-image: url('../imgs/icon-lang-down.svg');
      width: 1rem;
      height: 1rem;
      background-size: 1rem;
      background-repeat: no-repeat;
      background-position: center right;
      display: block;
      position: absolute;
      right: 0;
      top: 0.4rem;
      transition-duration: 0.3s;
      transition-property: transform;
    }
    &.js-show{
      &::after{
        transform: rotate(180deg);
      }
    }
  }  

  &__toggle{
    display: none;  
    position: absolute;
    right: 0;
    background-color: $white;
    flex-flow: column;
    box-shadow: 0 0 1rem #ccc;
    border-radius: 0.3rem;
    padding: 1rem 2rem;
    margin-top: 1rem;

    &::after {
      content: " ";
      position: absolute;
      right: 1.5rem;
      top: -1rem;
      border-top: none;
      border-right: 1rem solid transparent;
      border-left: 1rem solid transparent;
      border-bottom: 1rem solid $white;
    }

    &.js-show{
      display: flex;
    }
  }

  &__item{
    color: $black;
    text-decoration: none;
  }
}

.m-toggle{
  display: block;
  width: 2.5rem;
  height: 1.6rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  margin-left: 2rem;

  & span{
      display: block;
      position: absolute;
      height: 0.1rem;
      width: 100%;
      background: $black;
      border-radius: 0;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

      &:nth-child(1) {
          top: 0;
      }

      &:nth-child(2){
          top: 0.8rem;
      }

      &:nth-child(3){
          top: 0.8rem;
      }

      &:nth-child(4) {
          top: 1.6rem;
      }
  }

  &.js-open{
    & span{
        &:nth-child(1) {
            top: 0.8rem;
            width: 0%;
            left: 50%;
        }

        &:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        } 

        &:nth-child(4) {
            top: 1rem;
            width: 0%;
            left: 50%;
        }
    }
  }
}

.m-hamb{
  display: none;
  position: absolute;
  top: 85px;
  height: calc(100vh - 110px);
  width: 40rem;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 99;
  padding: 2rem;
  justify-content: flex-end;

  &::before{
    content: "";
    height: 100vh;
    background-color: rgba(255,255,255,0.8);
    width: 10000px;
    left: 100%;
    top: 0;
    position: absolute;
  }

  .ico-user,
  .ico-logout{
    display: block;
    @media(min-width: $md){
      display: none;
      margin-top: 4rem;
    }
  }

  &__user{
    display: flex;
    align-items: center;
    justify-content: end;
  }

  &.js-open{
    display: flex;
  }

  nav{
    display: flex;
    flex-flow: column;

    a{
      text-decoration: none;
      font-size: 2.4rem;
      line-height: 2.2rem;
      color: $black;
      text-align: right;
      padding-bottom: 1rem;
    }
  }
}

.ico-cart{
  width: 2rem;
  height: 2rem;
  background-image: url('/imgs/ico-cart.svg');
  display: block;
  position: relative;
  margin-right: 3rem;

  @media(max-width: $md){
    margin-right: 0;
  }

  span{
    font-family: $font_merri;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.6rem;
    color: $white;
    background-color: $green;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    position: absolute;
    display: flex;
    justify-content: center;
    top: -8px;
    right: -8px;
  }
}

.ico-user{
  background-image: url('/imgs/ico-user.svg');
  font-family: $font_bench;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.7rem;
  letter-spacing: 2px;
  text-align: right;
  text-transform: uppercase;
  padding-left: 3.6rem;
  background-position: left;
  background-repeat: no-repeat;
  color: $black;
  text-decoration: none;

  &--in{
    background-image: url('/imgs/ico-login.svg');
  }
}

.ico-logout{
  background-image: url('/imgs/ico-logout.svg');
  width: 2.1rem;
  height: 2rem;
  margin-left: 2rem;
  background-position: left;
  background-repeat: no-repeat;
  text-decoration: none;
}