html {
  /* 1rem = 10px */
  font-size: 62.5%;
}

*, div {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
}

body{
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: $font_bench; 
  font-weight: 400;
}

.container{
  max-width: 114rem;
  width: 100%;
  margin: 0 auto;
  padding: 0rem;

  &__bx{
    padding: 0 2rem;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;

    @media(max-width: $md){
      flex-flow: column;
    }

    
  }
}

a,
button{
  transition: all 300ms linear;
}

input,
select,
textarea{
  &:focus-visible{
    outline: none;
  }
}

img{
  display: block;
  height: auto;
}

strong{
  font-weight: 700;
}

.title{
  font-size: 4rem;
  line-height: 5rem;
  letter-spacing: 1.08px;

  @media(max-width: $md){
    font-size: 2.6rem;
    line-height: 3.6rem;
  }
}

.txt{
  font-family: $font_merri;

  ul,
  ol{
    margin-bottom: 1rem;
    li{
      position: relative;
      padding-bottom: 1rem;

      &:last-child{ 
        padding-bottom: 0;
      }

    }
  }

  ul{

    li{
      padding-left: 1.5rem;

      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 1.1rem;
        width: 0.5rem;
        height: 0.5rem;
        background-color: $green;
        border-radius: 100%;
      }
    }
  }

  ol{
    padding-left: 2rem;
    li{
      list-style: auto;
    }
  }
}

.up{
  text-transform: uppercase;
}

.page-content{
  margin-top: 8rem;

  p{
    margin-bottom: 1.6rem;
  }
}

.search{

  &.fancybox__content{
    padding: 5rem !important;
    width: 100%;
  }

  h2{
    text-align: center;
    font-size: 4rem;
    line-height: 4.6rem;
    margin-bottom: 4rem;
    font-weight: 700;
    color: $black;
  }

  &__fb{
    font-family: $font_merri;
    text-align: center;
  }

  form{
    position: relative;
    max-width: 32rem;
    margin: 0 auto;

    button{
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA0MCA0MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjM0MyAwLjU0OTU1MUM3Ljc3OTU3IDAuNTQ5NTUxIDAgOC4zMjkxMiAwIDE3Ljg5MjVDMCAyNy40NTYgNy43Nzk1NyAzNS4yMzU1IDE3LjM0MyAzNS4yMzU1QzIwLjI2NjUgMzUuMjM1NSAyMy4xNDA1IDM0LjU0MTggMjUuNTY4NSAzMy4yMDM5QzI1Ljc2MyAzMy40MzggMjUuOTc4NiAzMy42NTM2IDI2LjIxMjcgMzMuODQ4MUwzMS4xNjc4IDM4LjgwMzJDMzEuNjI1NCAzOS4zMTgxIDMyLjE4MzMgMzkuNzM0IDMyLjgwNzQgNDAuMDI1NEMzMy40MzE1IDQwLjMxNjggMzQuMTA4NiA0MC40Nzc1IDM0Ljc5NyA0MC40OTc4QzM1LjQ4NTUgNDAuNTE4MSAzNi4xNzA5IDQwLjM5NzQgMzYuODExIDQwLjE0MzJDMzcuNDUxMiAzOS44ODkgMzguMDMyNiAzOS41MDY3IDM4LjUxOTcgMzkuMDE5NkMzOS4wMDY3IDM4LjUzMjYgMzkuMzg5IDM3Ljk1MTIgMzkuNjQzMiAzNy4zMTFDMzkuODk3NCAzNi42NzA5IDQwLjAxODEgMzUuOTg1NSAzOS45OTc4IDM1LjI5N0MzOS45Nzc1IDM0LjYwODYgMzkuODE2OCAzMy45MzE1IDM5LjUyNTQgMzMuMzA3NEMzOS4yMzQgMzIuNjgzMyAzOC44MTgxIDMyLjEyNTQgMzguMzAzMiAzMS42Njc4TDMzLjM0ODEgMjYuNzEyN0MzMy4xMDY3IDI2LjQ3MTIgMzIuODQxIDI2LjI1NTQgMzIuNTU1MyAyNi4wNjg1QzMzLjg5MzIgMjMuNjQwNSAzNC43MzU1IDIwLjgxNjEgMzQuNzM1NSAxNy44NDNDMzQuNzM1NSA4LjI3OTU3IDI2Ljk1NiAwLjUgMTcuMzkyNSAwLjVMMTcuMzQzIDAuNTQ5NTUxWk0xNy4zNDMgNS41MDQ2OUMyNC4yMzA2IDUuNTA0NjkgMjkuNzMwOCAxMS4wMDQ5IDI5LjczMDggMTcuODkyNUMyOS43MzA4IDIxLjE2MjkgMjguNTQxNiAyNC4xODU2IDI2LjQ2MDQgMjYuNDE1NEMyNi40MTA5IDI2LjQ2NDkgMjYuMzYxMyAyNi41MTQ1IDI2LjMxMTggMjYuNTY0QzI2LjA3NzcgMjYuNzU4NSAyNS44NjIxIDI2Ljk3NDIgMjUuNjY3NiAyNy4yMDgyQzIzLjQ4NzQgMjkuMTkwMyAyMC41MTQzIDMwLjMyOTkgMTcuMjkzNCAzMC4zMjk5QzEwLjQwNTggMzAuMzI5OSA0LjkwNTU5IDI0LjgyOTcgNC45MDU1OSAxNy45NDIxQzQuOTA1NTkgMTEuMDU0NCAxMC40MDU4IDUuNTU0MjQgMTcuMjkzNCA1LjU1NDI0TDE3LjM0MyA1LjUwNDY5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
      background-color: $green;
      width: 4rem;
      height: 4rem;
      position: absolute;
      top: -8px;
      right: 0;
      border: 0;
      background-size: 2rem;
      border-radius: 100%;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

    }
  }

}