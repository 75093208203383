.mdl-hero{
  height: 38rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 0 2rem;
  position: relative;

  @media(max-width: $lg){
    height: 40rem;
  }

  &--home{
    @media(max-width: $lg){
      height: auto;
      padding: 0
    }

    img{
      display:none;

      @media(max-width: $lg){
        display: block;
        width: 100%
      }
    }
  }

  h1{
    font-size: 5rem;
    font-weight: 400;
    line-height: 6rem;
    letter-spacing: 0.02em;
    text-align: center;
    padding-bottom: 7.6rem;
    text-transform: uppercase;
    color: $white;
    z-index: 9;
    position: relative;

    @media(max-width: $lg){
      padding-bottom: 3rem;
      font-size: 3rem;
      line-height: 4rem;
    }
  }

  &__bg{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 53.46%, rgba(0, 0, 0, 0.50) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 8;
  }
}